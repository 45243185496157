
import update from 'immutability-helper'
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import axios from 'axios';

import Painel from "../../components/dragDrop/Painel";
import ContainerPrintPreview from "../../components/dragDrop/ContainerPrintPreview";
import { useLocation } from 'react-router';
import global from '../../utils/global';
import { ModalErro, ModalLoad, ModalSucesso } from '../../components/models/models';
import { tratamentoErro, cmToPx } from "../../utils/utils";
import Barcode from 'react-barcode';
import { wrapTextAsComponent } from '../../components/dragDrop/wrapTextAsComponent';

export default function CadEdicaoMoldes({user}) {

    const location = useLocation();
  
    // Pegando o valor de id da query string
    const queryParams = new URLSearchParams(location.search);
    const id_molde = queryParams.get('id');

    const fontFamilies = [
        'Arial', 'Verdana', 'Georgia', 'Times New Roman', 'Courier New', 'Trebuchet MS',
        'Impact', 'Comic Sans MS', 'Lucida Console', 'Tahoma', 'Helvetica', 'Courier',
        'Palatino', 'Garamond', 'Arial Black', 'sans-serif', 'serif', 'monospace'
    ];
    const fontWeights = ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

    //controles
    const [nomeMolde, setNomeMolde] = useState('');
    const [largura, setLargura] = useState(10);
    const [altura, setAltura] = useState(5);
    const [numeroColunas, setNumeroColunas] = useState(1);
    const [espacamento, setEspacamento] = useState(5);
    const [fontSizeNomeProduto, setFontSizeNomeProduto] = useState(15);
    const [fontSizePreco, setFontSizePreco] = useState(15);
    const [fontSizeDataValidade, setFontSizeDataValidade] = useState(15);
    const [fontWeithtNomeProduto, setFontWeigthNomeProduto] = useState('normal');
    const [fontWeithtPreco, setFontWeigthPreco] = useState('normal');
    const [fontWeithtDataValidade, setFontWeigthDataValidade] = useState('normal');
    const [fontFamilyNomeProduto, setFontFamilyNomeProduto] = useState('Arial');
    const [fontFamilyPreco, setFontFamilyPreco] = useState('Arial');
    const [fontFamilyDataValidade, setFontFamilyDataValidade] = useState('Arial');
    const [textTesteNomeProduto, setTextTesteNomeProduto] = useState('Nome do produto');
    const [textTestePreco, setTextTestePreco] = useState('Preço');
    const [textTesteDataValidade, setTextTesteDataValidade] = useState('Data de validade');
    const [textPreNomeProduto, setTextPreNomeProduto] = useState('');
    const [textPrePreco, setTextPrePreco] = useState('R$ ');
    const [textPreDataValidade, setTextPreDataValidade] = useState('Validade: ');
    const [codEanTeste] = useState('7894878067172');
    const [larguraCodBarras, setLarguraCodBarras] = useState(3);
    const [alturaCodBarras, setAlturaCodBarras] = useState(1);
    const [habilitaCodBarras, setHabilitaCodBarras] = useState(true);
    const [habilitaPreco, setHabilitaPreco] = useState(true);
    const [habilitaDataValidade, setHabilitaDataValidade] = useState(true);
    const [exibirCodEanNumerico, setExibirCodEanNumerico] = useState(false);
    const [formatoCodBarras, setFormatoCodBarras] = useState('CODE128');
    const [limiteCharsPorLinha, setLimiteCharsPorLinha] = useState(20);
    const [limiteCharsTotal, setLimiteCharsTotal] = useState(50);

    const [larguraLinhaPreview, setLarguraLinhaPreview] = useState(15);
    const [showMoreConfigm , setShowMoreConfigm] = useState(false);

    //modal load
    const [showModalLoad, setShowModalLoad] = useState(false);

    //modal erro
    const [showModalErro, setShowModalErro] = useState(false);
    const [msgModalErro, setMsgModalErro] = useState('');
    const [funcPosModalErro, setFuncPosModalErro] = useState(() => () => {});

    //modal sucesso
    const [showModalSucesso, setShowModalSucesso] = useState(false);
    const [msgModalSucesso, setMsgModalSucesso] = useState('');
    const [funcPosModalSucesso, setFuncPosModalSucesso] = useState(() => () => {});

    //componentes do drap and drop
    const [boxes, setBoxes] = useState({
        a: { top: 20, left: 100, text: wrapTextAsComponent((textPreNomeProduto + textTesteNomeProduto).slice(0, limiteCharsTotal), parseInt(limiteCharsPorLinha)), fontSize: fontSizeNomeProduto, fontWeight: fontWeithtNomeProduto, fontFamily: fontFamilyNomeProduto, fullWidth: true },//nome do produto
        b: { top: 20, left: 250, text: habilitaPreco ? (textPrePreco + textTestePreco) : "", fontSize: fontSizePreco, fontWeight: fontWeithtPreco, fontFamily: fontFamilyPreco },//Preço
        c: { top: 100, left: 100, text: habilitaDataValidade ? (textPreDataValidade + textTesteDataValidade) : "", fontSize: fontSizeDataValidade, fontWeight: fontWeithtDataValidade, fontFamily: fontFamilyDataValidade },//data de validade
        d: { top: 50, left: 100, 
            text: habilitaCodBarras ? (
                codEanTeste != null && codEanTeste != '' && 
                <Barcode
                    value={codEanTeste}
                    width={cmToPx(larguraCodBarras) / 100}
                    height={cmToPx(alturaCodBarras)}
                    margin={5}
                    displayValue={exibirCodEanNumerico}
                    format={formatoCodBarras}
                />)
                : null
        }//Cod ean do produto
    });

    //console.log(boxes);

    useEffect(() => {
        setBoxes(
            update(boxes, {
                a: {
                    $merge: { 
                        text: wrapTextAsComponent((textPreNomeProduto + textTesteNomeProduto).slice(0, limiteCharsTotal), parseInt(limiteCharsPorLinha)), 
                        fontSize: fontSizeNomeProduto, 
                        fontWeight: fontWeithtNomeProduto,
                        fontFamily: fontFamilyNomeProduto
                    }, // alterando texto, fontSize e fontWeight de 'a'
                },
                b: {
                    $merge: { 
                        text: habilitaPreco ? (textPrePreco + textTestePreco) : "", 
                        fontSize: fontSizePreco, 
                        fontWeight: fontWeithtPreco,
                        fontFamily: fontFamilyPreco
                    }, // alterando texto, fontSize e fontWeight de 'b'
                },
                c: {
                    $merge: { 
                        text: habilitaDataValidade ? (textPreDataValidade + textTesteDataValidade) : "", 
                        fontSize: fontSizeDataValidade, 
                        fontWeight: fontWeithtDataValidade,
                        fontFamily: fontFamilyDataValidade
                    }, // alterando texto, fontSize e fontWeight de 'c'
                },
                d: {
                    $merge: {
                        text: habilitaCodBarras ? <Barcode
                            value={codEanTeste}
                            width={cmToPx(larguraCodBarras) / 100}
                            height={cmToPx(alturaCodBarras)}
                            margin={5}
                            displayValue={exibirCodEanNumerico}
                            format={formatoCodBarras}
                        />
                        : null
                    }
                }
            })
        );
    }, [textTesteNomeProduto, fontSizeNomeProduto, fontWeithtNomeProduto, textTestePreco, fontSizePreco, fontWeithtPreco, textTesteDataValidade, fontSizeDataValidade, fontWeithtDataValidade, fontFamilyNomeProduto, fontFamilyPreco, fontFamilyDataValidade, habilitaCodBarras, codEanTeste, larguraCodBarras, alturaCodBarras, exibirCodEanNumerico, formatoCodBarras, textPreNomeProduto, textPrePreco, textPreDataValidade, habilitaPreco, habilitaDataValidade, limiteCharsPorLinha, limiteCharsTotal]); // Dependências para atualizar o estado quando qualquer um dos valores mudar

    useEffect(() => {

        if(id_molde != null){
            //buscar molde pelo id
            console.log("uuid: ", id_molde);
            
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: global.baseUrl + '/AppEtiqueta/BuscarUnicoMolde?uuid=' + id_molde,
                headers: { 
                    'ClientKey': user.clientKey,
                    'Content-Type': 'application/json'
                }
            };

            axios.request(config)
            .then((response) => {
                console.log(response);
                if(response.data.code == 0){
                    
                    let dados = response.data.content;
                    console.log(dados);
                    setNomeMolde(dados.nomeMolde);
                    setAltura(dados.altura);
                    setLargura(dados.largura);
                    setEspacamento(dados.espacamento);
                    setNumeroColunas(dados.numColunas);
                    setLarguraCodBarras(dados.larguraCodBarras);
                    setAlturaCodBarras(dados.alturaCodBarras);
                    setHabilitaCodBarras(dados.habilitaCodBarras);
                    setExibirCodEanNumerico(dados.exibirCodigoEanNumerico);
                    setFormatoCodBarras(dados.formatoCodBarras);
                    setTextTesteNomeProduto(dados.textoExemploNomeProduto);
                    setTextTestePreco(dados.textoExemploPreco);
                    setTextTesteDataValidade(dados.textoExemploDataVencimento);
                    setFontSizeNomeProduto(dados.fontSizeNomeProduto);
                    setFontSizePreco(dados.fontSizePreco);
                    setFontSizeDataValidade(dados.fontSizediasParaVencimento);
                    setFontWeigthNomeProduto(dados.fontWreigthNomeProduto);
                    setFontWeigthPreco(dados.fontWreigthPreco);
                    setFontWeigthDataValidade(dados.fontWreigthDiasVencimento);
                    setFontFamilyNomeProduto(dados.fontNomeProduto);
                    setFontFamilyPreco(dados.fontPreco);
                    setFontFamilyDataValidade(dados.fontDiasParaVencimento);
                    setTextPreNomeProduto(dados.textPreNomeProduto == null ? "": dados.textPreNomeProduto);
                    setTextPrePreco(dados.textPrePreco == null ? "" : dados.textPrePreco);
                    setTextPreDataValidade(dados.textPreDataValidade == null ? "" : dados.textPreDataValidade);
                    setHabilitaPreco(dados.habilitaPreco);
                    setHabilitaDataValidade(dados.habilitaDataValidade);
                    setLimiteCharsPorLinha(dados.limiteCharPorLinha);
                    setLimiteCharsTotal(dados.limiteCharTotal);
                    setBoxes(
                        update(boxes, {
                            a: { //nome produtos
                                $merge: { 
                                    top: dados.yNomeProduto,
                                    left: dados.xNomeProduto,
                                    text: wrapTextAsComponent((dados.textPreNomeProduto + dados.textoExemploNomeProduto).slice(0, dados.limiteCharTotal), parseInt(dados.limiteCharPorLinha)), 
                                    fontSize: dados.fontSizeNomeProduto, 
                                    fontWeight: dados.fontWreigthNomeProduto,
                                    fontFamily: dados.fontNomeProduto
                                },
                            },
                            b: { //preço
                                $merge: { 
                                    top: dados.yPreco,
                                    left: dados.xPreco,
                                    text: dados.habilitaPreco ? (dados.textPrePreco + dados.textoExemploPreco) : "" , 
                                    fontSize: dados.fontSizePreco, 
                                    fontWeight: dados.fontWreigthPreco,
                                    fontFamily: dados.fontPreco
                                },
                            },
                            c: { //data validade
                                $merge: { 
                                    top: dados.yDiasParaVencimento,
                                    left: dados.xDiasParaVencimento,
                                    text: dados.habilitaDataValidade ? (dados.textPreDataValidade + dados.textoExemploDataVencimento) : "" , 
                                    fontSize: dados.fontSizediasParaVencimento, 
                                    fontWeight: dados.fontWreigthDiasVencimento,
                                    fontFamily: dados.fontDiasParaVencimento
                                },
                            },
                            d: {
                                $merge: {
                                    top: dados.yCodigoBarra,
                                    left: dados.xCodigoBarra,
                                    text: dados.habilitaCodBarras ? <Barcode
                                        value={codEanTeste}
                                        width={cmToPx(dados.larguraCodBarras) / 100}
                                        height={cmToPx(dados.alturaCodBarras)}
                                        margin={5}
                                        displayValue={dados.exibirCodigoEanNumerico}
                                        format={dados.formatoCodBarras}
                                    />
                                    : null
                                }
                            }
                        })
                    );
                    
                }else{
                    setMsgModalErro('Erro ao buscar molde: ' + response.data.msg);
                    setShowModalErro(true);
                }
            })
            .catch((error) => {
                setMsgModalErro('Erro ao buscar molde: ' + tratamentoErro(error));
                setShowModalErro(true);
            })
            .finally(() => setShowModalLoad(false));
        }

    }, []);

    const funcSalvar = () => {

        console.log({
            nomeMolde: nomeMolde,
            largura: largura,
            altura: altura,
            numeroColunas: numeroColunas,
            espacamento: espacamento,
        });
        
        if(nomeMolde == "" || largura == "" || altura == "" || numeroColunas == "" || (espacamento == "" && espacamento != 0) ){
            setMsgModalErro('Erro ao salvar molde: Não pode haver campos vazios!');
            setShowModalErro(true);

            return;
        }

        if(boxes.a.fontSize == '' || boxes.b.fontSize == '' || boxes.c.fontSize == '' ){
            setMsgModalErro('Erro ao salvar molde: Defina o tamanho da fonte!');
            setShowModalErro(true);

            return;
        }

        setShowModalLoad(true);

        //salvar molde
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: global.baseUrl + '/AppEtiqueta/CadastrarEditarMolde',
            headers: { 
                'ClientKey': user.clientKey,
                'Content-Type': 'application/json'
            },
            data : {
                "uuidMolde": id_molde,
                "nome": nomeMolde,
                "largura": largura,
                "altura": altura,
                "numCol": numeroColunas,
                "espacamento": espacamento,
                "x_nomeProduto": boxes.a.left,
                "y_nomeProduto": boxes.a.top,
                "x_dataVencimento": boxes.c.left,
                "y_dataVencimento": boxes.c.top,
                "x_preco": boxes.b.left,
                "y_preco": boxes.b.top,
                "x_CodBarras": boxes.d.left,
                "y_CodBarras": boxes.d.top,
                "fontSizeNomeProduto": fontSizeNomeProduto,
                "fontSizeDataVencimento": fontSizeDataValidade,
                "fontSizePreco": fontSizePreco,
                "fontWreigthNomeProduto": fontWeithtNomeProduto,
                "fontWreigthDataVencimento": fontWeithtDataValidade,
                "fontWreigthPreco": fontWeithtPreco,
                "fontNomeProduto": fontFamilyNomeProduto,
                "fontDataVencimento": fontFamilyDataValidade,
                "fontPreco": fontFamilyPreco,
                "textoExemploNomeProduto": textTesteNomeProduto,
                "textoExemploDataVencimento": textTesteDataValidade,
                "textoExemploPreco": textTestePreco,
                "habilitaCodBarras": habilitaCodBarras,
                "alturaCodBarras": alturaCodBarras,
                "larguraCodBarras": larguraCodBarras,
                "exibirCodEanNumerico": exibirCodEanNumerico,
                "formatoCodBarras": formatoCodBarras,
                "textPreNomeProduto": textPreNomeProduto,
                "textPreDataValidade": textPreDataValidade,
                "textPrePreco": textPrePreco,
                "habilitaPreco": habilitaPreco,
                "habilitaDataValidade": habilitaDataValidade,
                "limiteCharPorLinha": parseInt(limiteCharsPorLinha),
                "limiteCharTotal": parseInt(limiteCharsTotal)
            }
        };

        axios.request(config)
        .then((response) => {
            console.log(response);
            if(response.data.code == 0){
                // setMsgModalSucesso('Molde salvo com sucesso!');
                // setShowModalSucesso(true);
                window.location.href = "/cadastrareditarmolde?id=" + response.data.content;
            }else{
                setMsgModalErro('Erro ao salvar molde: ' + response.data.msg);
                setShowModalErro(true);
            }
        })
        .catch((error) => {
            setMsgModalErro('Erro ao salvar molde: ' + tratamentoErro(error));
            setShowModalErro(true);
        })
        .finally(() => setShowModalLoad(false));

    };

    return(
        <div style={{marginRight: 50, marginLeft: 50}}>
            <ModalErro
                showModal={showModalErro}
                setShowModal={setShowModalErro}
                msg={msgModalErro}
                funcPos={funcPosModalErro}
            />
            <ModalSucesso
                showModal={showModalSucesso}
                setShowModal={setShowModalSucesso}
                msg={msgModalSucesso}
                funcPos={funcPosModalSucesso}
            />
            <ModalLoad showModal={showModalLoad} />
            <Row style={{}}>
                <h2>Cadastrar/Editar moldes</h2>
                <hr />
            </Row>
            <Row style={{marginTop: 10}}>
                <Col>
                    <Form.Label>Nome do molde</Form.Label>
                    <Form.Control
                        type="text"
                        value={nomeMolde}
                        onChange={(event) => setNomeMolde(event.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Espaçamento entre colunas: </Form.Label>
                    <Form.Control
                        type={'number'}
                        step={'0.1'}
                        min={0.1}
                        value={espacamento}
                        onChange={(e) => setEspacamento(e.target.value)}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: 10}}>
                
                <Col>
                    <Form.Label>Num colunas</Form.Label>
                    <Form.Control
                        type={'number'}
                        step={1}
                        min={1}
                        value={numeroColunas}
                        onChange={(e) => setNumeroColunas(e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Largura (cm)</Form.Label>
                    <Form.Control
                        type={'number'}
                        step={1}
                        min={1}
                        value={largura}
                        onChange={(e) => setLargura(e.target.value)}
                    />
                </Col>
                <Col>
                    <Form.Label>Altura (cm)</Form.Label>
                    <Form.Control
                        type={'number'}
                        step={1}
                        min={1}
                        value={altura}
                        onChange={(e) => setAltura(e.target.value)}
                    />
                </Col>
            </Row>
            <hr />
            <Row style={{marginBottom: 20}}>
                <Col>
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={funcSalvar}
                        >Salvar</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                {/* Botão Linha */}
                <div style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                    }}>
                    <hr 
                        style={{
                            flexGrow: 1,
                            border: 0,
                            borderTop: "1px solid black"
                        }}
                    />
                        <div style={{
                            padding: "0 10px",
                            fontWeight: "bold",
                            color: "black",
                        }}>
                            {
                                !showMoreConfigm ?
                                <CiCirclePlus onClick={() => {
                                    setShowMoreConfigm(true);
                                }} size={30} />
                                :
                                <CiCircleMinus onClick={() => {
                                    setShowMoreConfigm(false);
                                }} size={30} />

                            }
                        </div>
                    <hr 
                        style={{
                            flexGrow: 1,
                            border: 0,
                            borderTop: "1px solid black"
                        }}
                    />
                </div>
            </Row>
            {
                showMoreConfigm &&
                <div>
                    <Row style={{marginTop: 15}}>
                        <Col>
                            <div style={{padding: 5, border: "1px solid black", borderRadius: 5}}>
                                <Row style={{margin: 0}}>
                                    <h5>Nome Produto: </h5>
                                    <hr />
                                    <Row style={{marginLeft: 2}}>
                                        <Form.Label>Tamanho da fonte: </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            step={'1'}
                                            value={fontSizeNomeProduto}
                                            onChange={(e) => setFontSizeNomeProduto(e.target.value)}
                                        />
                                    </Row>
                                    <Row style={{marginLeft: 2, marginTop: 5}}>
                                        <Form.Label>Estilo da fonte: </Form.Label>
                                        <Form.Select 
                                            value={fontWeithtNomeProduto}
                                            onChange={(e) => setFontWeigthNomeProduto(e.target.value)}
                                        >
                                            {fontWeights.map((font) => (
                                                <option key={font} value={font}>
                                                    {font}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Row>
                                    <Row style={{marginLeft: 2, marginTop: 5}}>
                                        <Form.Label>Fonte: </Form.Label>
                                        <Form.Select 
                                            value={fontFamilyNomeProduto}
                                            onChange={(e) => setFontFamilyNomeProduto(e.target.value)}
                                        >
                                            {fontFamilies.map((font) => (
                                                <option key={font} value={font}>
                                                    {font}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Row>
                                    <Row style={{marginLeft: 2, marginTop: 5}}>
                                        <Form.Label>Texto Pré: </Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            value={textPreNomeProduto}
                                            onChange={(e) => setTextPreNomeProduto(e.target.value)}
                                        />
                                    </Row>
                                    <Row style={{marginLeft: 2, marginTop: 5}}>
                                        <Form.Label>Texto de exemplo: </Form.Label>
                                        <Form.Control
                                            type={'text'}
                                            value={textTesteNomeProduto}
                                            onChange={(e) => setTextTesteNomeProduto(e.target.value)}
                                        />
                                    </Row>
                                    <Row style={{marginLeft: 2, marginTop: 5}}>
                                        <Form.Label>Limitar characteres por linha: </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            step={'1'}
                                            value={limiteCharsPorLinha}
                                            onChange={(e) => setLimiteCharsPorLinha(e.target.value)}
                                        />
                                    </Row>
                                    <Row style={{marginLeft: 2, marginBottom: 10, marginTop: 5}}>
                                        <Form.Label>Limite characteres total: </Form.Label>
                                        <Form.Control
                                            type={'number'}
                                            step={'1'}
                                            value={limiteCharsTotal}
                                            onChange={(e) => setLimiteCharsTotal(e.target.value)}
                                        />
                                    </Row>
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div style={{padding: 5, border: "1px solid black", borderRadius: 5}}>
                                <Row style={{margin: 0}}>
                                    <Row>
                                        <Col
                                            md={10}
                                            xs={10}
                                            xxl={10}

                                        >
                                            <h5>Preço: </h5>
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                checked={habilitaPreco}
                                                onChange={(e) => setHabilitaPreco(e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        habilitaPreco &&
                                        <>
                                            <hr />
                                            <Row style={{marginLeft: 2}}>
                                                <Form.Label>Tamanho da fonte: </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    step={'1'}
                                                    value={fontSizePreco}
                                                    onChange={(e) => setFontSizePreco(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Estilo da fonte: </Form.Label>
                                                <Form.Select 
                                                    value={fontWeithtPreco}
                                                    onChange={(e) => setFontWeigthPreco(e.target.value)}
                                                >
                                                    {fontWeights.map((font) => (
                                                        <option key={font} value={font}>
                                                            {font}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Fonte: </Form.Label>
                                                <Form.Select 
                                                    value={fontFamilyPreco}
                                                    onChange={(e) => setFontFamilyPreco(e.target.value)}
                                                >
                                                    {fontFamilies.map((font) => (
                                                        <option key={font} value={font}>
                                                            {font}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Texto Pré: </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    value={textPrePreco}
                                                    onChange={(e) => setTextPrePreco(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginBottom: 10, marginTop: 5}}>
                                                <Form.Label>Texto de exemplo: </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    value={textTestePreco}
                                                    onChange={(e) => setTextTestePreco(e.target.value)}
                                                />
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                        </>
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 15}}>
                        <Col>
                            <div style={{padding: 5, border: "1px solid black", borderRadius: 5}}>
                                <Row style={{margin: 0}}>
                                    <Row>
                                        <Col
                                            md={10}
                                            xs={10}
                                            xxl={10}

                                        >
                                            <h5>Data validade: </h5>
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                checked={habilitaDataValidade}
                                                onChange={(e) => setHabilitaDataValidade(e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        habilitaDataValidade &&
                                        <>
                                            <hr />
                                            <Row style={{marginLeft: 2}}>
                                                <Form.Label>Tamanho da fonte: </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    step={'1'}
                                                    value={fontSizeDataValidade}
                                                    onChange={(e) => setFontSizeDataValidade(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Estilo da fonte: </Form.Label>
                                                <Form.Select 
                                                    value={fontWeithtDataValidade}
                                                    onChange={(e) => setFontWeigthDataValidade(e.target.value)}
                                                >
                                                    {fontWeights.map((font) => (
                                                        <option key={font} value={font}>
                                                            {font}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Fonte: </Form.Label>
                                                <Form.Select 
                                                    value={fontFamilyDataValidade}
                                                    onChange={(e) => setFontFamilyDataValidade(e.target.value)}
                                                >
                                                    {fontFamilies.map((font) => (
                                                        <option key={font} value={font}>
                                                            {font}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Texto Pré: </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    value={textPreDataValidade}
                                                    onChange={(e) => setTextPreDataValidade(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginBottom: 10, marginTop: 5}}>
                                                <Form.Label>Texto de exemplo: </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    value={textTesteDataValidade}
                                                    onChange={(e) => setTextTesteDataValidade(e.target.value)}
                                                />
                                            </Row>
                                        </>
                                    }
                                </Row>
                            </div>
                        </Col>
                        <Col>
                            <div style={{padding: 5, border: "1px solid black", borderRadius: 5}}>
                                <Row style={{margin: 0}}>
                                    <Row>
                                        <Col
                                            md={10}
                                            xs={10}
                                            xxl={10}

                                        >
                                            <h5>Codigo de barras: </h5>
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="switch"
                                                checked={habilitaCodBarras}
                                                onChange={(e) => setHabilitaCodBarras(e.target.checked)}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        habilitaCodBarras &&
                                        <>
                                            <hr />
                                            <Row style={{marginLeft: 2}}>
                                                <Form.Label>Lagura: </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    step={'0.1'}
                                                    value={larguraCodBarras}
                                                    onChange={(e) => setLarguraCodBarras(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Altura: </Form.Label>
                                                <Form.Control
                                                    type={'number'}
                                                    step={'0.1'}
                                                    value={alturaCodBarras}
                                                    onChange={(e) => setAlturaCodBarras(e.target.value)}
                                                />
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 5}}>
                                                <Form.Label>Formato: </Form.Label>
                                                <Form.Select
                                                    value={formatoCodBarras}
                                                    onChange={(e) => setFormatoCodBarras(e.target.value)}
                                                >
                                                    {
                                                        ["CODE39",
                                                        "CODE128",
                                                        "CODE128A",
                                                        "CODE128B",
                                                        "CODE128C",
                                                        "EAN13",
                                                        "EAN8",
                                                        "EAN5",
                                                        "EAN2",
                                                        "UPC",
                                                        "UPCE",
                                                        "ITF14",
                                                        "ITF",
                                                        "MSI",
                                                        "MSI10",
                                                        "MSI11",
                                                        "MSI1010",
                                                        "MSI1110",
                                                        "pharmacode",
                                                        "codabar",
                                                        "GenericBarcode"]
                                                        .map((form, index) => 
                                                            <option value={form} key={index} >{form}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </Row>
                                            <Row style={{marginLeft: 2, marginTop: 30, marginBottom: 30}}>
                                                <Col
                                                    md={10}
                                                    xs={10}
                                                    xxl={10}
                                                >
                                                    Exibir código numerico:
                                                </Col>
                                                <Col>
                                                    <Form.Check
                                                        type="switch"
                                                        checked={exibirCodEanNumerico}
                                                        onChange={(e) => setExibirCodEanNumerico(e.target.checked)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                            <Row>
                                                &nbsp;
                                            </Row>
                                        </>
                                    }

                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            <hr />
            <Row>
                <Col style={{ 
                    whiteSpace: 'nowrap',
                    justifyContent: "center", 
                    alignItems: "center", 
                    display: "flex",
                    marginTop: 20, 
                    marginBottom: 20, 
                    //border: "1px solid black",
                    overflowX: "scroll", // Ativa rolagem vertical
                }}>
                    <Painel style={{
                        marginTop: 10, 
                        marginBottom: 20,
                    }} height={altura} width={largura} boxes={boxes} setBoxes={setBoxes} editable />
                </Col>
            </Row>
            <Row >
                <Row>
                    <h3 style={{marginRight: 10}}>Preview</h3>
                </Row>
                <Row>
                    <Form.Label>Largura (cm) da linha: {larguraLinhaPreview} </Form.Label>
                    <Form.Range value={larguraLinhaPreview} onChange={(e) => {console.log(e.target.value); setLarguraLinhaPreview(e.target.value);}} />
                </Row>
                <hr/>
                <Row style={{ 
                    justifyContent: "center", 
                    alignItems: "center", 
                    display: "flex"
                }}>
                    {/* Preview */}
                    <ContainerPrintPreview width={largura} height={altura} boxes={boxes} num_col={numeroColunas} espacamento={espacamento}/>
                </Row>
                <Row style={{marginLeft: 0}}>
                    <hr style={{
                        width: `${larguraLinhaPreview}cm`,
                        border: 'none',
                        borderTop: `${5}px solid black`
                    }} />
                </Row>
            </Row>
        </div>
    );
}
