import React from "react";
import { memo } from "react";

function getStyles(left, top, isDragging) {
    const transform = `translate3d(${left}px, ${top}px, 0)`;
    return {
        position: 'absolute',
        transform,
        WebkitTransform: transform,
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    };
}

const ContainerPrintPreview = memo(({ width, height, boxes, num_col, espacamento, habilitaBorda = true, styles = {} }) => {
    return (
        <div
            style={{
                whiteSpace: 'nowrap',
                overflowX: "auto",
                overflowY: "hidden",
                maxWidth: '100%',
                //height: 'auto',
                height: `${height}cm`,
                boxSizing: 'border-box',
                ...styles
            }}
        >
            {Array.from({ length: num_col }).map((_, index) => (
                <div
                    key={index}
                    style={{
                        width: `${width}cm`,
                        height: `${height}cm`,
                        border: habilitaBorda ? '1px solid black' : '',
                        position: 'relative',
                        display: 'inline-block',
                        maxHeight: 'auto',
                        marginRight: `${espacamento}px`, // Dinâmico com espaçamento em pixels
                        overflow: 'hidden',
                    }}
                >
                    {Object.keys(boxes).map((key) => (
                        <div
                            key={key}
                            id={key}
                            style={{
                                ...getStyles(boxes[key].left, boxes[key].top, false), 
                                ...(boxes[key].fullWidth && { width: '100%' }), // Aplica o estilo condicionalmente
                            }}
                            role="DraggableBox"
                        >
                            <div style={{ 
                                fontSize: `${boxes[key].fontSize}pt`, 
                                fontWeight: `${boxes[key].fontWeight}`, 
                                fontFamily: `${boxes[key].fontFamily}`, 
                                textAlign: 'center',

                            }} 
                            >
                                <p>{boxes[key].text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
});

export default ContainerPrintPreview;
